import StylesPhoneInputServiceInterface
  from '~/src/components/mui-wrappers/styled/phone-input/styles/StylesPhoneInputServiceInterface'
import StylesPhoneInputServiceStyleOne
  from '~/src/components/mui-wrappers/styled/phone-input/styles/StylesPhoneInputServiceStyleOne'
import StylesPhoneInputServiceStyleTwo
  from '~/src/components/mui-wrappers/styled/phone-input/styles/StylesPhoneInputServiceStyleTwo'
import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'

const getPhoneInputTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesPhoneInputServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesPhoneInputServiceStyleTwo(muiTheme)
    default:
      return new StylesPhoneInputServiceStyleOne(muiTheme)
  }
}

export default getPhoneInputTemplateService
