import { IconButton, Tooltip } from '@mui/material'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { useTranslations } from '~/src/hooks/useTranslations'

const WrongPhoneNumberFormatTooltip = () => {
  const { t } = useTranslations()

  return (
    <Tooltip title={t('errors.phone_number_no_longer_valid')}>
      <IconButton color='warning'>
        <WarningAmberOutlinedIcon />
      </IconButton>
    </Tooltip>
  )
}

export default WrongPhoneNumberFormatTooltip
