import MuiPhoneNumber, { MuiPhoneNumberProps } from 'material-ui-phone-number-2'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  Box,
  BoxProps,
  buttonBaseClasses,
  FormHelperText,
  InputLabel,
  nativeSelectClasses,
  outlinedInputClasses,
  styled
} from '@mui/material'
import useGetPhoneInputTemplate from '~/src/components/mui-wrappers/styled/phone-input/styles/useGetPhoneInputTemplate'
import { PlatformUtils } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import FormikFieldPropsInterface from '~/src/types/FormikFieldProps'
import ValidatorUtils from '~/src/utils/ValidatorUtils'
import WrongPhoneNumberFormatTooltip from '~/src/components/utils/tooltip/WrongPhoneNumberFormatTooltip'

interface Props extends BoxProps<'div'> {
  PhoneInputProps: FormikFieldPropsInterface & Omit<MuiPhoneNumberProps, 'size' | 'name' | 'onChange'>,
}

const PhoneInput = styled(({
  PhoneInputProps: { sx = {}, size, form, name, label, helperText, required, ...otherPhoneInputProps },
  ...boxProps
}: Props) => {
  const { isDesktop } = PlatformUtils(window)
  const inputElement = useRef(null)
  const [ displayChangePhoneNumberHint, setDisplayChangePhoneNumberHint ] = useState<boolean>(false)
  const firm = useEoValue(FIRM_SELECTOR)

  const _getPadding = (): number | string | undefined => {
    if (size === 'extra-small') {
      return 1
    }
    if (size === 'small') {
      return 1.3
    }
    if (size === 'giant') {
      return 2
    }

    return '12px'
  }

  const hasError = (): boolean | undefined => {
    return form.touched[name] && !!form.errors[name]
  }

  const handlePhoneChange = (value: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    form.setFieldValue(name, value)

    if (!document.activeElement === (inputElement.current as any).inputRef) {
      (inputElement.current as any).inputRef.focus()
    }
  }

  useEffect(() => {
    if (form.touched[name] || ValidatorUtils.isPhoneNumberValid(form.values[name])) {
      displayChangePhoneNumberHint && setDisplayChangePhoneNumberHint(false)
      return
    }

    setDisplayChangePhoneNumberHint(true)
  }, [ form.values[name] ])

  return (
    <Box {...boxProps}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <InputLabel
          required={required}
          sx={{ color: 'inherit' }}>
          {label}
        </InputLabel>
        <FormHelperText
          id={`${name}-helper-text`}
          sx={{
            color: 'inherit'
          }}
        >
          {helperText}
        </FormHelperText>
      </Box>
      <MuiPhoneNumber
        id={name}
        name={name}
        inputMode='tel'
        autoComplete='none'
        InputProps={{
          endAdornment: displayChangePhoneNumberHint && <WrongPhoneNumberFormatTooltip />,
          required
        }}
        fullWidth
        ref={inputElement}
        value={form.values[name]}
        error={hasError()}
        helperText={hasError() ? form.errors[name] as string : undefined}
        onChange={handlePhoneChange}
        onBlur={form.handleBlur}
        // @ts-ignore
        native={!isDesktop}
        defaultCountry={firm?.address.country?.iso.toLowerCase() || 'be'}
        variant='outlined'
        sx={{
          [`& .${outlinedInputClasses.root} > input`]: {
            pt: _getPadding(),
            pb: _getPadding()
          },
          [`.${nativeSelectClasses.select}`]: {
            boxSizing: 'border-box'
          },
          [`.${nativeSelectClasses.icon}`]: {
            width: '1.5em',
            height: '1.5em',
            top: 'inherit'
          },
          [`.${buttonBaseClasses.root}`]: {
            '&>svg': {
              width: '1em',
              height: '1em'
            }
          },
          ...sx
        }}
        {...otherPhoneInputProps}
      />
    </Box>
  )
})(() => useGetPhoneInputTemplate().getComponentSx())

export default PhoneInput
