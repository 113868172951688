import getPhoneInputTemplateService
  from '~/src/components/mui-wrappers/styled/phone-input/styles/GetPhoneInputTemplateService'
import StylesPhoneInputServiceInterface
  from '~/src/components/mui-wrappers/styled/phone-input/styles/StylesPhoneInputServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'

const useGetPhoneInputTemplate = (): StylesPhoneInputServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle()

  return getPhoneInputTemplateService(pageStyle, muiTheme)
}

export default useGetPhoneInputTemplate
